.graf-container {
    position: relative;
}

.graf-text-container {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    z-index: 1;
}

.graf-header {
    font-weight: 300;
    color: #3C3C3C;
}

.graf-footer {
    font-weight: 700;
    color: #3C3C3C;
}

.legend-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
/*    margin: 20px 0;*/
}

.legend-color {
    height: 20px;
    width: 20px;
    margin-right: 16px;
}

.legend-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.legend-description {
    color: #3C3C3C;
    font-size: 14px;
    font-weight: 300;
}

.legend-total {
    color: #3C3C3C;
    font-size: 14px;
    font-weight: 700;
}

.legend-wrapper {
    border-bottom: 1px solid #E9E9E9;
    width: 100%;
}