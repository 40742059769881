.settings-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 16px;
}

.user-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: red;
    
}

.project-wrapper {
    display: flex;
    flex-wrap: wrap;
}

    .project-wrapper > .project {
        border: 1px solid gray;
        padding: 0 2px;
        margin: 2px;
        background-color: lightgray;
        border-radius: 4px;
    }

/*svg.icon-button {
    color: red;
}*/