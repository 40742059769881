body,
div,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td,
menu {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img,
abbr {
  border: 0;
}
address,
caption,
cite,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
sup {
  vertical-align: text-top;
}
sub {
  vertical-align: text-bottom;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
legend {
  color: #000;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
img {
  max-width: 100%;
  height: auto;
}
* :not(svg, path) {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    color: #354245;
    box-sizing: border-box;
}
@media screen and (max-width: 560px) {
  * {
    font-size: 16px;
  }
}
* .container {
  width: 100%;
  margin: 0 auto;
  padding-left: 5px;
  padding-right: 5px;
}
@media screen and (max-width: 780px) {
  * .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}
* .container .row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
* .container .col {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 5px;
  margin-right: 5px;
}
body {
  background: #F2F2F2;
}
body .loginWrapper {
  background: #E9E9E9;
  max-width: 1400px;
  margin: 0 auto;
}
body .loginWrapper header {
  height: 221px;
}
@media screen and (max-width: 560px) {
  body .loginWrapper header {
    height: auto;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper header {
    flex-direction: column;
  }
}
body .loginWrapper header .header-left {
  max-width: 560px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 780px) {
  body .loginWrapper header .header-left {
    max-width: 300px;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper header .header-left {
    height: 100px;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper header .header-left {
    padding-top: 15px;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper header .header-left {
    padding-bottom: 15px;
  }
}
body .loginWrapper header .header-left img {
  height: unset;
}
@media screen and (max-width: 560px) {
  body .loginWrapper header .header-left img {
    height: 68px;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper header .header-left svg {
    height: 80px;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper header .header-left svg {
    width: 80px;
  }
}
body .loginWrapper header .header-right {
  background: url("../img/header.png") no-repeat center;
  background-size: contain;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 560px) {
  body .loginWrapper header .header-right {
    display: none;
  }
}
body .loginWrapper header .header-right-en {
  background: url("../img/headerEN.png") no-repeat center;
  background-size: contain;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 560px) {
  body .loginWrapper header .header-right-en {
    display: none;
  }
}
body .loginWrapper header .header-right-ro {
  background: url("../img/headerRO.png") no-repeat center;
  background-size: contain;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 560px) {
  body .loginWrapper header .header-right-ro {
    display: none;
  }
}
body .loginWrapper header .header-right-ru {
  background: url("../img/headerRU.png") no-repeat center;
  background-size: contain;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 560px) {
  body .loginWrapper header .header-right-ru {
    display: none;
  }
}
body .loginWrapper header > img {
  display: none;
}
@media screen and (max-width: 560px) {
  body .loginWrapper header > img {
    display: flex;
  }
}
body .loginWrapper main {
  min-height: calc(100vh - 221px);
}
@media screen and (max-width: 560px) {
  body .loginWrapper main {
    min-height: calc(100vh - 250px);
  }
}
body .loginWrapper main .container {
  padding-left: 100px;
  padding-right: 100px;
}
@media screen and (max-width: 560px) {
  body .loginWrapper main .container {
    padding-left: 24px;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper main .container {
    padding-right: 24px;
  }
}
body .loginWrapper main h1 {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: 'Merriweather', serif;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #3C3C3C;
  padding: 34px 0;
}
@media screen and (max-width: 560px) {
  body .loginWrapper main h1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper main h1 {
    line-height: 30px;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper main h1 {
    justify-content: center;
  }
}
body .loginWrapper main h1:before {
  content: "";
  width: 100%;
  margin-right: 30px;
  height: 1px;
  background: #D8AF62;
}
@media screen and (max-width: 560px) {
  body .loginWrapper main h1:before {
    display: none;
  }
}
body .loginWrapper main h1:after {
  content: "";
  width: 100%;
  margin-left: 30px;
  height: 1px;
  background: #D8AF62;
}
@media screen and (max-width: 560px) {
  body .loginWrapper main h1:after {
    margin-left: unset;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper main h1:after {
    position: absolute;
  }
}
@media screen and (max-width: 560px) {
  body .loginWrapper main h1:after {
    bottom: 14px;
  }
}
body .loginWrapper main .links {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  margin: 26px 0 0;
  padding: 0;
}
body .loginWrapper main .links li {
  position: relative;
  padding: 0 10px;
}
body .loginWrapper main .links li.active a {
  color: #D8AF62;
}
body .loginWrapper main .links li:first-child:after {
  position: absolute;
  content: "";
  width: 1px;
  right: 0;
  top: 2px;
  height: 23px;
  background: #D8AF62;
}
body .loginWrapper main .links li a {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #3C3C3C;
  text-decoration: none;
  padding: 0 10px;
}
body .loginWrapper main form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  margin: 0 auto;
  padding-bottom: 50px;
}
@media screen and (max-width: 560px) {
  body .loginWrapper main form {
    max-width: 100%;
  }
}
body .loginWrapper main form span {
  display: block;
  width: 100%;
  position: relative;
}
body .loginWrapper main form span.login:before {
  position: absolute;
  content: url('../img/user.svg');
  width: 15px;
  height: 15px;
  z-index: 10;
  top: 28px;
  left: 15px;
}
body .loginWrapper main form span.password:before {
  position: absolute;
  content: url('../img/password.svg');
  width: 15px;
  height: 15px;
  z-index: 10;
  top: 28px;
  left: 15px;
}
body .loginWrapper main form input {
  border: 1px solid #D8AF62;
  width: 100%;
  border-radius: 15px;
  padding: 15px 15px 14px 46px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 15px 0 10px;
  position: relative;
}
body .loginWrapper main form input::placeholder {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #C0C0C0;
}
body .loginWrapper main form button {
  background: #D8AF62;
  border-radius: 15px;
  border: none;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #FFFFFF;
  padding: 13px 50px;
  cursor: pointer;
}
body .loginWrapper main form div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 35px;
}
body .loginWrapper main form div label {
  font-size: 14px;
  color: #D8AF62;
  cursor: pointer;
}
body .loginWrapper main form div input[type=checkbox] {
  display: none;
}
body .loginWrapper main form div input[type=checkbox] + label {
  padding-left: 23px;
  position: relative;
}
body .loginWrapper main form div input[type=checkbox] + label::before {
  display: block;
  border: 1px solid #D8AF62;
  border-radius: 5px;
  left: 0;
  top: 7px;
  height: 13px;
  width: 11px;
  padding-left: 2px;
  content: "";
  position: absolute;
  line-height: 14px;
  cursor: pointer;
}
body .loginWrapper main form div input[type=checkbox]:invalid + label {
  color: red;
}
body .loginWrapper main form div input[type=checkbox]:invalid + label::before {
  border-color: red;
}
body .loginWrapper main form div input[type=checkbox]:checked + label::before {
  content: "✓";
}
body .loginWrapper main form div a {
  font-size: 14px;
  color: #D8AF62;
  padding-right: 5px;
}
body .loginWrapper main .fl {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  margin: 26px 0 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #D8AF62;
  text-decoration: none;
  padding: 0 10px;
}
@-webkit-keyframes autofilluser {
  to {
    background-image: url("../img/user.svg");
  }
}
@-webkit-keyframes autofillpass {
  to {
    background: url("../img/password.svg") no-repeat 15px;
  }
}
header {
    height: 64px;
/*    height: 128px;*/
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E9E9E9;
    background: #2D2E2E;
}
@media screen and (max-width: 780px) {
  header {
    height: 80px;
  }
}
header .header-left {
  width: calc(100% / 12 * 3.5);
  display: flex;
  justify-content: end;
  padding-right: 28px;
}
@media screen and (max-width: 780px) {
  header .header-left {
    width: 40%;
  }
}
@media screen and (max-width: 780px) {
  header .header-left {
    justify-content: left;
  }
}
@media screen and (max-width: 780px) {
  header .header-left {
    align-items: center;
  }
}
@media screen and (max-width: 780px) {
  header .header-left {
    padding-right: 10px;
  }
}
header .header-left_wrap {
  max-width: 317px;
  display: flex;
  width: 100%;
  align-items: center;
}
@media screen and (max-width: 780px) {
  header .header-left_wrap {
    justify-content: space-evenly;
  }
}
header .header-left img {
  height: 50px;
}
@media screen and (max-width: 780px) {
  header .header-left img {
    height: 60px;
  }
}
@media screen and (max-width: 780px) {
  header .header-left img {
    padding-left: 15px;
  }
}
header .header-left .menu__btn {
  display: none;
}
@media screen and (max-width: 780px) {
  header .header-left .menu__btn {
    display: flex;
  }
}
header .header-right {
  width: calc(100% / 12 * 8.5);
  height: 100%;
  background: #D8AF62;
  display: flex;
  align-items: center;
  padding-left: 53px;
}
@media screen and (max-width: 780px) {
  header .header-right {
    width: 60%;
  }
}
@media screen and (max-width: 780px) {
  header .header-right {
    padding-left: 15px;
  }
}
header .header-right .cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 821px;
  width: 100%;
}
header .header-right .title {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #FFFFFF;
}
@media screen and (max-width: 780px) {
  header .header-right .title {
    font-size: 24px;
  }
}
header .header-right .search {
  position: relative;
}
@media screen and (max-width: 780px) {
  header .header-right .search {
    display: none;
  }
}
header .header-right .search input {
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  background: #D8AF62;
  color: #FFFFFF;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 25px;
}
header .header-right .search input::placeholder {
  color: #FFFFFF;
  font-weight: 400;
}
header .header-right .search button {
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  width: 18px;
  height: 18px;
  top: calc(50% - 9px);
  right: 9px;
  cursor: pointer;
}
header .header-right .search button img {
  display: block;
}
header .header-right .lk {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 780px) {
  header .header-right .lk {
    flex-direction: column;
  }
}
@media screen and (max-width: 780px) {
  header .header-right .lk {
    align-items: baseline;
  }
}
header .header-right .lk select {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAxTDYgNkwxIDEiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 95% 50%;
  min-width: 60px;
  display: inline-block;
  margin-right: 15px;
  color: #ffffff;
  border: none;
}
header .header-right .lk a {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
}
header .header-right .lk a:hover {
  text-decoration: underline;
}
#language-selector {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAxTDYgNkwxIDEiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 95% 50%;
  min-width: 60px;
  display: inline-block;
  margin-right: 15px;
  color: #ffffff;
  border: none;
  outline: 0;
  cursor: pointer;
}
main {
  background: #FFFFFF;
  min-height: calc(100vh - 128px);
}
main .left {
  background: #2D2E2E;
  width: calc(100% / 12 * 3.5);
  padding-top: 67px;
}
@media screen and (max-width: 780px) {
  main .left {
    display: none;
  }
}
main menu {
  display: flex;
  align-items: end;
  flex-flow: column;
  list-style: none;
  height: calc(100vh - 195px);
}
main menu > li {
  display: flex;
  flex-direction: column;
  max-width: 345px;
  width: 100%;
  border-bottom: 1px solid #555555;
  position: relative;
}
main menu > li.active > a {
  background: #4D4D4D;
}
main menu > li.dropdown img {
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  z-index: 1001;
  margin-left: 18px;
  padding: 10px;
  height: 30px;
  right: 15px;
}
main menu > li.dropdown img.rotate {
  rotate: 180deg;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
main menu > li ul {
  margin: 0;
  padding: 0 0 0 42px;
  list-style: none;
  overflow: hidden;
  /* Hide the element content, while height = 0 */
  max-height: 0;
  opacity: 0;
  transition: max-height 300ms, opacity 300ms;
}
main menu > li ul.visible {
  max-height: 700px;
  opacity: 1;
  transition: max-height 300ms, opacity 300ms;
}
main menu > li ul li {
  display: flex;
  border-top: 1px solid #555555;
}
main menu > li ul li.active {
  background: #4D4D4D;
}
main menu > li ul li a {
  padding: 10px 20px;
}
main menu > li a {
  padding: 32px 70px 32px 24px;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-decoration: none;
  color: #D8AF62;
  width: 100%;
  position: relative;
}
main .content {
  box-sizing: border-box;
  word-wrap: break-word;
  margin-left: 5px;
  margin-right: 5px;
  width: calc(100% / 12 * 8.5 - 10px);
  max-width: 874px;
  padding-left: 50px;
  margin-bottom: 50px;
}
@media screen and (max-width: 780px) {
  main .content {
    width: calc(100% / 12 * 12 - 10px);
  }
}
@media screen and (max-width: 780px) {
  main .content {
    padding: 10px 0 10px;
  }
}
main .content .filter {
  min-height: 67px;
  align-items: center;
  display: flex;
    margin-top: 4px;
    margin-bottom: 8px;
}
@media screen and (max-width: 780px) {
  main .content .filter {
    height: unset;
  }
}
@media screen and (max-width: 780px) {
  main .content .filter {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 780px) {
  main .content .filter {
    justify-content: flex-end;
  }
}
main .content .filter img {
  padding-right: 7px;
}
main .content .filter > span {
  display: block;
  border-right: 1px solid #D8AF62;
  height: 33px;
  padding-right: 20px;
  color: #D8AF62;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 780px) {
  main .content .filter > span {
    border-right: none;
  }
}
@media screen and (max-width: 780px) {
  main .content .filter > span {
    padding-right: 5px;
  }
}
main .content .filter > div {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 780px) {
  main .content .filter > div {
    display: none;
  }
}
@media screen and (max-width: 780px) {
  main .content .filter > div select {
    margin-bottom: 5px;
  }
}
main .content .name {
  border-radius: 3px;
  height: 46px;
  color: #FFFFFF;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  text-transform: uppercase;
  padding: 6px 25px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
main .content .name:after {
  content: "";
  position: absolute;
  border-left: 7px solid transparent !important;
  border-right: 7px solid transparent !important;
  border-top: 7px solid transparent;
  bottom: -6px;
  left: 38px;
}
main .content .name span {
  display: block;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: right;
  text-transform: uppercase;
  color: #FFFFFF;
}
main .content .block {
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  padding: 14px 20px 8px;
  color: #3C3C3C;
  border-bottom: 6px solid transparent;
}
main .content .block .count {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 41px;
  text-align: center;
  display: flex;
  color: #3C3C3C;
}
main .content .block .title {
  font-size: 14px;
  line-height: 19px;
  font-weight: 300;
  font-family: 'Open Sans';
  color: #3C3C3C;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
main .content .block .title.sb {
  justify-content: space-between;
}
main .content .block .title.bold {
  font-weight: bold;
}
main .content .block .title.right {
  justify-content: flex-end;
}
main .content .block .title a {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #D9AF62;
}
main .content .block .title a img {
  margin-left: 10px;
}
main .content .block.h100 {
  height: calc(100% - 10px);
}
main .content .block_row {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}
@media screen and (max-width: 780px) {
  main .content .block_row {
    flex-direction: column;
  }
}
main .content .block_row_col {
  width: 100%;
  margin-left: 15px;
}
main .content .block .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0;
}
main .content .block .info:not(:last-child) {
  border-bottom: 1px solid #E9E9E9;
}
main .content .block .info_name {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #3C3C3C;
  margin-right: 16px;
  text-align: left;
}
main .content .block .info_name span {
  display: block;
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 16px;
}
main .content .block .info_count {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #3C3C3C;
}
main .content .form .block {
  margin-bottom: 26px;
}
main .content .form .top {
  margin-bottom: 28px;
}
@media screen and (max-width: 780px) {
  main .content .form .top {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 780px) {
  main .content .form .top {
    display: flex;
  }
}
@media screen and (max-width: 780px) {
  main .content .form .top {
    flex-direction: column;
  }
}
@media screen and (max-width: 780px) {
  main .content .form .top {
    width: 100%;
  }
}
@media screen and (max-width: 780px) {
  main .content .form .top {
    align-items: flex-start;
  }
}
main .content .form .top input {
  width: 180px;
}
@media screen and (max-width: 780px) {
  main .content .form .top input {
    width: 100%;
  }
}
main .content .form .top input:not(:last-child) {
  margin-right: 20px;
}
main .content .form .bottom {
  text-align: left;
  width: 100%;
}
main .content .form .bottom input {
  width: 100%;
}
main .content .form .bottom > div {
  display: flex;
  margin-bottom: 28px;
  align-items: center;
}
@media screen and (max-width: 780px) {
  main .content .form .bottom > div {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 780px) {
  main .content .form .bottom > div {
    flex-direction: column;
  }
}
@media screen and (max-width: 780px) {
  main .content .form .bottom > div {
    align-items: flex-start;
  }
}
main .content .form .bottom > div label {
  white-space: nowrap;
  margin-right: 10px;
}
main .content .form label {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3C3C3C;
}
main .content .form input {
  border: 1px solid #C0C0C0;
  border-radius: 10px;
  font-size: 16px;
  padding: 0 10px;
}
main .content .form button {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  border-radius: 10px;
  padding: 12px 25px;
  max-width: 243px;
  width: 100%;
  border: none;
  background: #3C3C3C;
  color: #FFFFFF;
  text-transform: uppercase;
}
@media screen and (max-width: 780px) {
  main .content .form button {
    margin-bottom: 10px;
  }
}
main .content .form button:not(:last-child) {
  margin-right: 20px;
}
main .content .form button.lime {
  background: #93C01F;
}
main .content .table {
  width: 100%;
  margin-top: 20px;
}
main .content .table thead {
  background: #3C3C3C;
}
main .content .table thead td {
  color: #ffffff;
}
main .content .table td {
  padding: 5px 10px;
  border-bottom: 1px solid #555555;
}
.s4 {
  width: calc(100% / 12 * 4 - 10px);
}
@media screen and (max-width: 1020px) {
  .s4 {
    width: calc(100% / 12 * 6 - 10px);
  }
}
@media screen and (max-width: 780px) {
  .s4 {
    width: calc(100% / 12 * 12 - 10px);
  }
}
.s6 {
  width: calc(100% / 12 * 6 - 10px);
}
@media screen and (max-width: 780px) {
  .s6 {
    width: calc(100% / 12 * 12 - 10px);
  }
}
.s12 {
  width: calc(100% / 12 * 12 - 10px);
}
.lime {
  border-color: #83B000 !important;
  background: #83B000;
}
.lime:after {
  border-color: #83B000 !important;
}
.red {
  border-color: #F05161 !important;
  background: #F05161;
}
.red:after {
  border-color: #F05161 !important;
}
.blue {
  border-color: #30B2E6 !important;
  background: #30B2E6;
}
.blue:after {
  border-color: #30B2E6 !important;
}
.purple {
  border-color: #8268BA !important;
  background: #8268BA;
}
.purple:after {
  border-color: #8268BA !important;
}
.yellow {
  border-color: #FFDC25 !important;
  background: #FFDC25;
}
.yellow:after {
  border-color: #FFDC25 !important;
}
.green {
  border-color: #00B051 !important;
  background: #00B051;
}
.green:after {
  border-color: #00B051 !important;
}
.darkturquoise {
  border-color: #45D1BA !important;
  background: #45D1BA;
}
.darkturquoise:after {
  border-color: #45D1BA !important;
}
.pink {
  border-color: #FF69C3 !important;
  background: #FF69C3;
}
.pink:after {
  border-color: #FF69C3 !important;
}
.orange {
  border-color: #D9AF62 !important;
  background: #D9AF62;
}
.orange:after {
  border-color: #D9AF62 !important;
}
.gray {
  border-color: #979797 !important;
  background: #979797;
}
.gray:after {
  border-color: #979797 !important;
}
.btn {
  background: #18C0D6;
  border-radius: 4px;
  color: #EBFFF8;
  padding: 14px 20px;
  box-shadow: none;
  width: 100%;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.btn-notw {
  width: unset;
}
.btn-border {
  border: 2px solid #18C0D6;
  background: #ffffff;
  color: #18C0D6;
  padding: 13px 24px;
}
.btn-large {
  padding: 17px 28px;
  font-size: 15px;
  line-height: 18px;
  color: #354245;
  font-weight: 500;
  width: unset;
}
.btn-cyan {
  color: #FFFFFF;
}
.btn-shadow {
  box-shadow: 0 8px 16px rgba(53, 66, 69, 0.08);
}
.btn-white {
  background: #FFFFFF;
  color: #354245;
}
.btn_coll {
  position: absolute;
  top: 75px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E7E9;
  box-sizing: border-box;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 19px 0;
  z-index: 10;
}
.btn_coll:before {
  position: absolute;
  content: "";
  background: #FFFFFF;
  top: -7px;
  left: calc(50% - 6px);
  width: 12px;
  height: 12px;
  border-left: 1px solid #E1E7E9;
  border-top: 1px solid #E1E7E9;
  transform: rotate(45deg);
}
.btn_coll a {
  display: block;
  text-decoration: none;
  font-size: 18px;
  line-height: 24px;
  padding: 9px 28px;
  color: #354245;
}
@media screen and (max-width: 560px) {
  .btn_coll a {
    font-size: 16px;
  }
}
.btn_coll a:hover {
  background: #F0F0F0;
}
.btn img {
  margin-bottom: unset;
  margin-right: 15px;
  vertical-align: middle;
}
.btn-last {
  background: #F0F0F0;
  margin-left: 8px;
  width: 70px;
}
.btn-last img {
  filter: none;
  margin-right: unset;
}
.center {
  display: flex;
  width: 100%;
  justify-content: center;
}
.hide {
  display: none;
}
.select {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAxTDYgNkwxIDEiIHN0cm9rZT0iI0MwQzBDMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 95% 60%;
  position: relative;
  display: block;
  min-width: 188px;
  border: 1px solid #C0C0C0;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 6px 14px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Merriweather';
  color: #C0C0C0;
  margin-left: 21px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.select.on {
  padding: 10px 15px;
  border: 2px solid #18C0D6;
}
.mobile {
  display: none;
  position: absolute;
  visibility: hidden;
  top: 79px;
  left: 0;
  background: #2D2E2E;
  width: 70%;
  height: calc(100vh - 128px);
  z-index: 1001;
}
@media screen and (max-width: 780px) {
  .mobile {
    height: calc(100vh - 79px);
  }
}
@media screen and (max-width: 780px) {
  .mobile {
    display: block;
  }
}
.mobile menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #555555;
  margin-bottom: 15px;
}
.mobile menu > li {
  display: flex;
  flex-direction: column;
  max-width: 345px;
  width: 100%;
  border-bottom: 1px solid #555555;
  position: relative;
}
.mobile menu > li.active > a {
  background: #4D4D4D;
}
.mobile menu > li.dropdown img {
  width: 30px;
  position: absolute;
  top: calc(50% - 15px);
  z-index: 1001;
  margin-left: 18px;
  padding: 10px;
  height: 30px;
  right: 15px;
}
.mobile menu > li.dropdown img.rotate {
  rotate: 180deg;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.mobile menu > li ul {
  margin: 0;
  padding: 0 0 0 42px;
  list-style: none;
  display: block;
  max-height: 0;
  opacity: 0;
  transition: max-height 300ms, opacity 300ms;
}
.mobile menu > li ul.visible {
  max-height: 700px;
  opacity: 1;
  transition: max-height 300ms, opacity 300ms;
}
.mobile menu > li ul li {
  display: flex;
  border-top: 1px solid #555555;
}
.mobile menu > li ul li.active {
  background: #4D4D4D;
}
.mobile menu > li ul li a {
  padding: 10px 20px;
}
.mobile menu > li a {
  padding: 32px 70px 32px 24px;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-decoration: none;
  color: #D8AF62;
  width: 100%;
  position: relative;
}
.btn-group {
  display: flex;
}
@media screen and (max-width: 780px) {
  .btn-group {
    flex-direction: column;
  }
}
.btn-group button {
  margin-left: 10px;
  padding: 4px 25px !important;
  width: auto !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  border-radius: 10px;
  border: none;
  background: #3C3C3C;
  color: #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;
}
@media screen and (max-width: 780px) {
  .btn-group button {
    width: 100%;
  }
}
@media screen and (max-width: 780px) {
  .btn-group button {
    margin-top: 10px;
  }
}
@media screen and (max-width: 780px) {
  .btn-group button {
    margin-left: 0;
  }
}
.icon-button {
  cursor: pointer;
}
#menu__toggle {
  opacity: 0;
}
.menu__btn {
  display: flex;
  /* используем flex для центрирования содержимого */
  align-items: center;
  /* центрируем содержимое кнопки */
  position: relative;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}
/* добавляем "гамбургер" */
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}
#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .mobile {
  visibility: visible;
}
/* свойства модального окна по умолчанию */
.modal {
  position: fixed;
  /* фиксированное положение */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  /* цвет фона */
  z-index: 1050;
  opacity: 1;
  /* по умолчанию модальное окно прозрачно */
  -webkit-transition: opacity 200ms ease-in;
  -moz-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in;
  /* анимация перехода */
  pointer-events: auto;
  /* элемент невидим для событий мыши */
  overflow-y: auto;
  margin: 0;
  padding: 0;
}
/* ширина модального окна и его отступы от экрана */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
    margin: 30px auto;
    /* для отображения модального окна по центру */
  }
}
/* свойства для блока, содержащего контент модального окна */
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
/* свойства для заголовка модального окна */
.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #eceeef;
}
.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
  color: #000000;
}
/* свойства для кнопки "Закрыть" */
.close {
  float: right;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  text-decoration: none;
}
/* свойства для кнопки "Закрыть" при нахождении её в фокусе или наведении */
.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.75;
}
/* свойства для блока, содержащего основное содержимое окна */
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 15px;
  overflow: auto;
  color: #000000;
}
.modal-body form > div {
  width: 100%;
}
.modal-body form > div input {
  width: 100%;
  border: 1px solid #C0C0C0;
  border-radius: 10px;
  font-size: 16px;
  padding: 0 10px;
}
.modal-body form > div select {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAxTDYgNkwxIDEiIHN0cm9rZT0iI0MwQzBDMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 98% 60%;
  width: 100%;
  margin-left: 0 !important;
}
.modal-body form > button {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  border-radius: 10px;
  padding: 12px 25px;
  max-width: 243px;
  width: 100%;
  border: none;
  background: #3C3C3C;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
}
@media screen and (max-width: 780px) {
  .modal-body form > button {
    margin-bottom: 10px;
  }
}
.modal-body form > button:not(:last-child) {
  margin-right: 20px;
}
.modal-body form > button.lime {
  background: #93C01F;
}
.select-projects__control {
  min-height: 32px !important;
  border-radius: 10px !important;
}
.select-projects__multi-value__label {
  font-size: 75% !important;
  line-height: 16px !important;
}
.select-projects__input-container {
  line-height: 16px !important;
}
.select-projects__input-container__input {
  font-size: 13px !important;
}
.select-projects__indicator {
  padding: 4px 8px !important;
}
.select-projects__indicator path {
  color: silver;
}
.select-years-filter {
    min-width: 188px;
    margin-left: 20px;
}
@media screen and (max-width: 780px) {
    .select-years-filter {
        padding-bottom: 8px;
    }
}
.select-years-filter .select-years__control {
  min-height: 32px !important;
  border-radius: 10px !important;
}
.select-years-filter .select-years__single-value {
  line-height: 26px !important;
  font-size: 15px !important;
}
.select-years-filter .select-years__value-container input {
  line-height: 26px !important;
}
.select-years-filter .select-years__indicator {
  padding: 4px 8px !important;
}
.select-years-filter .select-years__indicator path {
  color: silver;
}
.select-months-filter {
  min-width: 188px;
  margin-left: 20px;
}
.select-months-filter .select-months__control {
  min-height: 32px !important;
  border-radius: 10px !important;
}
.select-months-filter .select-months__multi-value__label {
  font-size: 75% !important;
  line-height: 16px !important;
}
.select-months-filter .select-months__value-container input {
  line-height: 26px !important;
}
.select-months-filter .select-months__indicator {
  padding: 4px 8px !important;
}
.select-months-filter .select-months__indicator path {
  color: silver;
}

.select-stage-filter {
    min-width: 188px;
    margin-left: 20px;
}

    .select-stage-filter .select-stage__control {
        min-height: 32px !important;
        border-radius: 10px !important;
    }

    .select-stage-filter .select-stage__menu {
        z-index: 2;
    }

    .select-stage-filter .select-stage__single-value {
        line-height: 26px !important;
        font-size: 15px !important;
    }

    .select-stage-filter .select-stage__value-container input {
        line-height: 26px !important;
    }

    .select-stage-filter .select-stage__indicator {
        padding: 4px 8px !important;
    }

        .select-stage-filter .select-stage_indicator path {
            color: silver;
        }


.forgot-description {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 16px !important;
}

.back-link {
    display: block;
    text-decoration: none;
    font-size: 14px;
    line-height: 24px;
    padding: 9px 28px;
    color: #D8AF62;

}

.error-text {
    justify-content: center !important;
    color: red;
    font-size: 14px;
}
