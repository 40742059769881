@import "smart-grid.less";

.reset();

* :not(svg, path) {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    .xs(font-size, 16px);
    line-height: 32px;
    color: #354245;
    box-sizing: border-box;

    .container {
        width: 100%;
        .wrapper();

        .row {
            .row-flex();
        }

        .col {
            .col();
        }
    }
}
body {
  background: #F2F2F2;
  .loginWrapper {
    background: #E9E9E9;
    max-width: 1400px;
    margin: 0 auto;
    header {
      height: 221px;
      .xs(height, auto);
      .xs(flex-direction, column);
      .header-left {
        max-width: 560px;
        .sm(max-width, 300px);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .xs(height, 100px);
        .xs(padding-top, 15px);
        .xs(padding-bottom, 15px);
        img {
          height: unset;
          .xs(height, 68px);
        }
        svg {
          .xs(height, 80px);
          .xs(width, 80px);
        }
      }
      .header-right {
        background: url("../img/header.png") no-repeat center;
        background-size: contain;
        height: 100%;
        width: 100%;
        .xs(display, none);
        &-en {
          background: url("../img/headerEN.png") no-repeat center;
          background-size: contain;
          height: 100%;
          width: 100%;
          .xs(display, none);
        }
        &-ro {
          background: url("../img/headerRO.png") no-repeat center;
          background-size: contain;
          height: 100%;
          width: 100%;
          .xs(display, none);
        }
        &-ru {
          background: url("../img/headerRU.png") no-repeat center;
          background-size: contain;
          height: 100%;
          width: 100%;
          .xs(display, none);
        }
      }
      > img {
        display: none;
        .xs(display, flex);
      }
    }
    main {
      min-height: calc(100vh - 221px);
      .xs(min-height, calc(100vh - 250px));
      .container {
        padding-left: 100px;
        padding-right: 100px;
        .xs(padding-left, 24px);
        .xs(padding-right, 24px);
      }
      h1 {
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        white-space: nowrap;
        font-family: 'Merriweather', serif;
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        .xs(font-size, 24px);
        line-height: 40px;
        .xs(line-height, 30px);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: #3C3C3C;
        padding: 34px 0;
        .xs(justify-content, center);
        &:before {
          content: "";
          width: 100%;
          margin-right: 30px;
          height: 1px;
          background: #D8AF62;
          .xs(display, none);
        }
        &:after {
          content: "";
          width: 100%;
          margin-left: 30px;
          .xs(margin-left, unset);
          height: 1px;
          background: #D8AF62;
          .xs(position, absolute);
          .xs(bottom, 14px);
        }
      }
      .links {
        display: flex;
        flex-direction: row;
        list-style: none;
        justify-content: center;
        margin: 26px 0 0;
        padding: 0;
        li{
          position: relative;
          padding: 0 10px;
          &.active {
            a {
              color: #D8AF62;
            }
          }
          &:first-child {
            &:after {
              position: absolute;
              content: "";
              width: 1px;
              right: 0;
              top:2px;
              height: 23px;
              background: #D8AF62;
            }
          }
          a {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            color: #3C3C3C;
            text-decoration: none;
            padding: 0 10px;
          }
        }
      }
      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 320px;
        .xs(max-width, 100%);
        margin: 0 auto;
        padding-bottom: 50px;
        span {
          display: block;
          width: 100%;
          position: relative;
          &.login {
            &:before {
              position: absolute;
              content: url('../img/user.svg');
              width: 15px;
              height: 15px;
              z-index: 10;
              top: 28px;
              left: 15px;
            }
          }

          &.password {
            &:before {
              position: absolute;
              content: url('../img/password.svg');
              width: 15px;
              height: 15px;
              z-index: 10;
              top: 28px;
              left: 15px;
            }
          }
        }
          input {
            border: 1px solid #D8AF62;
            width: 100%;
            border-radius: 15px;
            padding: 15px 15px 14px 46px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            margin: 15px 0 10px;
            position: relative;

            &::placeholder {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;
              color: #C0C0C0;
            }
          }
        button {
          background: #D8AF62;
          border-radius: 15px;
          border: none;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          color: #FFFFFF;
          padding: 13px 50px;
          cursor: pointer;
        }
        div {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 35px;
          label {
            font-size: 14px;
            color: #D8AF62;
            cursor: pointer;
          }
          input[type=checkbox] { display: none; }
          input[type=checkbox] + label {
            padding-left: 23px;
            position: relative;
          }
          input[type=checkbox] + label::before {
            display: block;
            border: 1px solid #D8AF62;
            border-radius: 5px;
            left:0;
            top: 7px;
            height: 13px;
            width: 11px;
            padding-left:2px;
            content: "";
            position: absolute;
            line-height: 14px;
            cursor: pointer;
          }
          input[type=checkbox]:invalid + label { color: red; }
          input[type=checkbox]:invalid + label::before {
            border-color: red;
          }
          input[type=checkbox]:checked + label::before {
            content: "✓";
          }
          a {
            font-size: 14px;
            color: #D8AF62;
            padding-right: 5px;
          }
        }
      }
      .fl {
        display: flex;
        flex-direction: row;
        list-style: none;
        justify-content: center;
        margin: 26px 0 0;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #D8AF62;
        text-decoration: none;
        padding: 0 10px;
      }
    }
  }
}
@-webkit-keyframes autofilluser {
  to {
    background-image:url("../img/user.svg");
  }
}
@-webkit-keyframes autofillpass {
  to {
    background: url("../img/password.svg") no-repeat 15px;
  }
}
header {
  height: 128px;
  .sm(height, 80px);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #E9E9E9;
  background: #2D2E2E;
  .header-left {
    width: calc(100% / 12 * 3.5);
    .sm(width, 40%);
    display: flex;
    justify-content: end;
    .sm(justify-content, left);
    .sm(align-items, center);
    padding-right: 28px;
    .sm(padding-right, 10px);
    &_wrap {
      max-width: 317px;
      display: flex;
      width: 100%;
      align-items: center;
      .sm(justify-content, space-evenly);
    }
    img {
      height: 74px;
      .sm(height, 60px);
      .sm(padding-left, 15px);
    }
    .menu__btn {
      display: none;
      .sm(display, flex);
    }
  }
  .header-right {
    width: calc(100% / 12 * 8.5);
    .sm(width, 60%);
    height: 100%;
    background: #D8AF62;
    display: flex;
    align-items: center;
    padding-left: 53px;
    .sm(padding-left, 15px);
    .cont {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 821px;
      width: 100%;
    }
    .title {
      font-family: 'Merriweather';
      font-style: normal;
      font-weight: 900;
      font-size: 32px;
      .sm(font-size, 24px);
      line-height: 40px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #FFFFFF;
    }
    .search {
      position: relative;
      .sm(display, none);
      input {
        border: 1px solid #FFFFFF;
        border-radius: 10px;
        background: #D8AF62;
        color: #FFFFFF;
        font-family: 'Merriweather';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding: 7px 25px;
        &::placeholder {
          color: #FFFFFF;
          font-weight: 400;
        }
      }
      button {
        position: absolute;
        background: none;
        border: none;
        padding: 0;
        width: 18px;
        height: 18px;
        top: calc(50% - 9px);
        right: 9px;
        cursor: pointer;
        img {
          display: block;
        }
      }
    }
    .lk {
      display: flex;
      align-items: center;
      .sm(flex-direction, column);
      .sm(align-items, baseline);
      select {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAxTDYgNkwxIDEiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 95% 50%;
        min-width: 60px;
        display: inline-block;
        margin-right: 15px;
        color:#ffffff;
        border: none;
      }
      a {
        color:#ffffff;
        font-size: 16px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
#language-selector {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAxTDYgNkwxIDEiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 95% 50%;
  min-width: 60px;
  display: inline-block;
  margin-right: 15px;
  color: #ffffff;
  border: none;
  outline: 0;
  cursor: pointer;
}
main {
  background: #FFFFFF;
  min-height: calc(100vh - 128px);
  .left {
    background: #2D2E2E;
    width: calc(100% / 12 * 3.5);
    padding-top: 67px;
    .sm(display, none);
  }
  menu {
    display: flex;
    align-items: end;
    flex-flow: column;
    list-style: none;
    height: calc(100vh - 195px);
    >li {
      display: flex;
      flex-direction: column;
      max-width: 345px;
      width: 100%;
      border-bottom: 1px solid #555555;
      position: relative;
      &.active {
        > a {
          background: #4D4D4D;
        }
      }
      &.dropdown {
        img {
          width: 30px;
          position: absolute;
          top: calc(50% - 15px);
          z-index: 1001;
          margin-left: 18px;
          padding: 10px;
          height: 30px;
          right: 15px;
          &.rotate {
            rotate: 180deg;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
          }
        }
      }
      ul {
        margin: 0;
        padding: 0 0 0 42px;
        list-style: none;
        overflow: hidden;  /* Hide the element content, while height = 0 */
        max-height: 0;
        opacity: 0;
        transition: max-height 300ms, opacity 300ms;
        &.visible {
          max-height: 700px; opacity: 1;
          transition: max-height 300ms, opacity 300ms;
        }
        li {
          display: flex;
          border-top: 1px solid #555555;
          &.active {
            background: #4D4D4D;
          }
          a {
            padding: 10px 20px;
          }
        }
      }
      a {
        padding: 32px 70px 32px 24px;
        font-family: 'Merriweather';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-decoration: none;
        color: #D8AF62;
        width: 100%;
        position: relative;
      }
    }
  }
  .content {
    .col();
    .size(8.5);
    .size-sm(12);
    max-width: 874px;
    padding-left: 50px;
    .sm(padding, 10px 0 10px);
    margin-bottom: 50px;
    .filter {
      height: 67px;
      .sm(height, unset);
      .sm(margin-bottom, 10px);
      align-items: center;
      display: flex;
      .sm(justify-content, flex-end);
      img {
        padding-right: 7px;
      }
      > span {
        display: block;
        border-right: 1px solid #D8AF62;
        .sm(border-right, none);
        height: 33px;
        padding-right: 20px;
        .sm(padding-right, 5px);
        color: #D8AF62;
        font-family: 'Merriweather';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
      }
      > div {
        display: flex;
        align-items: center;
        .sm(display, none);
        select {
          .sm(margin-bottom, 5px);
        }
      }
    }
    .name {
      border-radius: 3px;
      height: 46px;
      color: #FFFFFF;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 22px;
      line-height: 30px;
      text-transform: uppercase;
      padding: 6px 25px;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        //background: #35A8E0;
        border-left: 7px solid transparent !important;
        border-right: 7px solid transparent !important;
        border-top: 7px solid transparent;
        bottom: -6px;
        left: 38px;
      }
      span {
        display: block;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        text-align: right;
        text-transform: uppercase;
        color: #FFFFFF;
      }
    }
    .block {
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      padding: 14px 20px 8px;
      color: #3C3C3C;
      border-bottom: 6px solid transparent;
      .count {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 41px;
        text-align: center;
        display: flex;
        color: #3C3C3C;
      }
      .title {
        font-size: 14px;
        line-height: 19px;
        font-weight: 300;
        font-family: 'Open Sans';
        color: #3C3C3C;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        &.sb {
          justify-content: space-between;
        }
        &.bold {
          font-weight: bold;
        }
        &.right {
          justify-content: flex-end;
        }
        a {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          text-decoration-line: underline;
          color: #D9AF62;
          img {
            margin-left: 10px;
          }
        }
      }
      &.h100 {
        height: calc(100% - 10px);
        //justify-content: center;
      }
      &_row {
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        .sm(flex-direction, column);
        &_col {
          width: 100%;
          margin-left: 15px;
        }
      }
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &:not(:last-child) {
          border-bottom: 1px solid #E9E9E9;
        }
        padding: 5px 0;
        &_name {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 19px;
          color: #3C3C3C;
          margin-right: 16px;
          text-align: left;
          span {
            display: block;
            float: left;
            width: 20px;
            height: 20px;
            margin-right: 16px;
          }
        }
        &_count {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;
          color: #3C3C3C;
        }
      }
    }
    .form {
      .block {
        margin-bottom: 26px;
      }
      .top {
        margin-bottom: 28px;
        .sm(margin-bottom, 0);
        .sm(display, flex);
        .sm(flex-direction, column);
        .sm(width, 100%);
        .sm(align-items, flex-start);
        input {
          width: 180px;
          .sm(width, 100%);
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
      .bottom {
        text-align: left;
        width: 100%;
        input {
          width: 100%;
        }
        >div {
          display: flex;
          margin-bottom: 28px;
          .sm(margin-bottom, 0);
          align-items: center;
          .sm(flex-direction, column);
          .sm(align-items, flex-start);
          label {
            white-space: nowrap;
            margin-right: 10px;
          }
        }
      }
      label {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #3C3C3C;
      }
      input {
        border: 1px solid #C0C0C0;
        border-radius: 10px;
        font-size: 16px;
        padding: 0 10px;
      }
      button {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        border-radius: 10px;
        padding: 12px 25px;
        max-width: 243px;
        width: 100%;
        border: none;
        background: #3C3C3C;
        color: #FFFFFF;
        text-transform: uppercase;
        .sm(margin-bottom, 10px);
        &:not(:last-child){
          margin-right: 20px;
        }
        &.lime {
          background: #93C01F;
        }
      }
    }
    .table {
      width: 100%;
      margin-top: 20px;
      thead {
        background: #3C3C3C;
        td {
          color: #ffffff;

        }
      }
      td {
        padding: 5px 10px;
        border-bottom: 1px solid #555555;
      }
    }
  }
}
.s4 {
  .size(4);
  .size-md(6);
  .size-sm(12);
}
.s6 {
  .size(6);
  .size-sm(12);
}
.s12 {
  .size(12);
}
.lime {
  border-color: #83B000 !important;
  background: #83B000;
  &:after {
    border-color: #83B000 !important;
  }
}
.red {
  border-color: #F05161 !important;
  background: #F05161;
  &:after {
    border-color: #F05161 !important;
  }
}
.blue {
  border-color: #30B2E6 !important;
  background: #30B2E6;
  &:after {
    border-color: #30B2E6 !important;
  }
}
.purple {
  border-color: #8268BA !important;
  background: #8268BA;
  &:after {
    border-color: #8268BA !important;
  }
}
.yellow {
  border-color: #FFDC25 !important;
  background: #FFDC25;
  &:after {
    border-color: #FFDC25 !important;
  }
}
.green {
  border-color: #00B051 !important;
  background: #00B051;
  &:after {
    border-color: #00B051 !important;
  }
}
.darkturquoise {
  border-color: #45D1BA !important;
  background: #45D1BA;
  &:after {
    border-color: #45D1BA !important;
  }
}
.pink {
  border-color: #FF69C3 !important;
  background: #FF69C3;
  &:after {
    border-color: #FF69C3 !important;
  }
}
.orange {
  border-color: #D9AF62 !important;
  background: #D9AF62;
  &:after {
    border-color: #D9AF62 !important;
  }
}
.gray {
  border-color: #979797 !important;
  background: #979797;
  &:after {
    border-color: #979797 !important;
  }
}

.btn {
  background: #18C0D6;
  border-radius: 4px;
  color: #EBFFF8;
  padding: 14px 20px;
  box-shadow: none;
  width: 100%;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &-notw {
    width: unset;
  }
  &-border {
    border: 2px solid #18C0D6;
    background: #ffffff;
    color: #18C0D6;
    padding: 13px 24px;
  }
  &-large {
    padding: 17px 28px;
    font-size: 15px;
    line-height: 18px;
    color: #354245;
    font-weight: 500;
    width: unset;
  }
  &-cyan {
    color: #FFFFFF;
  }
  &-shadow {
    box-shadow: 0 8px 16px rgba(53, 66, 69, 0.08);
  }
  &-white {
    background: #FFFFFF;
    color: #354245;
  }
  &_coll {
    position: absolute;
    top: 75px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E1E7E9;
    box-sizing: border-box;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 19px 0;
    z-index: 10;
    &:before {
      position: absolute;
      content: "";
      background: #FFFFFF;
      top: -7px;
      left: calc(50% - 6px);
      width: 12px;
      height: 12px;
      border-left: 1px solid #E1E7E9;
      border-top: 1px solid #E1E7E9;
      transform: rotate(45deg);
    }
    a {
      display: block;
      text-decoration: none;
      font-size: 18px;
      .xs(font-size, 16px);
      line-height: 24px;
      padding: 9px 28px;
      color: #354245;
      &:hover {
        background: #F0F0F0;
      }
    }
  }
  img {
    margin-bottom: unset;
    margin-right: 15px;
    vertical-align: middle;
  }
  &-last {
    background: #F0F0F0;
    margin-left: 8px;
    width: 70px;
    img {
      filter: none;
      margin-right: unset;
    }
  }
}
.center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.hide {
  display: none;
}


.select {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAxTDYgNkwxIDEiIHN0cm9rZT0iI0MwQzBDMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 95% 60%;
  position: relative;
  display: block;
  min-width: 188px;
  border: 1px solid #C0C0C0;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 6px 14px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Merriweather';
  color: #C0C0C0;
  margin-left: 21px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &.on {
    padding: 10px 15px;
    border: 2px solid #18C0D6;
  }
}
.mobile {
  display: none;
  position: absolute;
  visibility: hidden;
  top: 79px;
  left: 0;
  background: #2D2E2E;
  width: 70%;
  height: calc(100vh - 128px);
  .sm(height, calc(100vh - 79px));
  z-index: 1001;
  .sm(display, block);
  menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #555555;
    margin-bottom: 15px;
    >li {
      display: flex;
      flex-direction: column;
      max-width: 345px;
      width: 100%;
      border-bottom: 1px solid #555555;
      position: relative;
      &.active {
        > a {
          background: #4D4D4D;
        }
      }
      &.dropdown {
        img {
          width: 30px;
          position: absolute;
          top: calc(50% - 15px);
          z-index: 1001;
          margin-left: 18px;
          padding: 10px;
          height: 30px;
          right: 15px;
          &.rotate {
            rotate: 180deg;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
          }
        }
      }
      ul {
        margin: 0;
        padding: 0 0 0 42px;
        list-style: none;
        display: block;
        max-height: 0;
        opacity: 0;
        transition: max-height 300ms, opacity 300ms;
        &.visible {
          max-height: 700px; opacity: 1;
          transition: max-height 300ms, opacity 300ms;
        }
        li {
          display: flex;
          border-top: 1px solid #555555;
          &.active {
            background: #4D4D4D;
          }
          a {
            padding: 10px 20px;
          }
        }
      }
      a {
        padding: 32px 70px 32px 24px;
        font-family: 'Merriweather';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        text-decoration: none;
        color: #D8AF62;
        width: 100%;
        position: relative;
      }
    }
  }
}
.btn-group {
  display: flex;
  .sm(flex-direction, column);
  button {
    margin-left: 10px;
    padding: 4px 25px !important;
    width: auto !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    border-radius: 10px;
    .sm(width, 100%);
    border: none;
    background: #3C3C3C;
    color: #FFFFFF;
    text-transform: uppercase;
    cursor: pointer;
    .sm(margin-top, 10px);
    .sm(margin-left, 0);
  }
}
.icon-button {
  cursor: pointer;
}
#menu__toggle {
  opacity: 0;
}
.menu__btn {
  display: flex; /* используем flex для центрирования содержимого */
  align-items: center;  /* центрируем содержимое кнопки */
  position: relative;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}
/* добавляем "гамбургер" */
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}
#menu__toggle:checked ~ .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .mobile {
  visibility: visible;
}
/* свойства модального окна по умолчанию */
.modal {
  position: fixed; /* фиксированное положение */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5); /* цвет фона */
  z-index: 1050;
  opacity: 1; /* по умолчанию модальное окно прозрачно */
  -webkit-transition: opacity 200ms ease-in;
  -moz-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in; /* анимация перехода */
  pointer-events: auto; /* элемент невидим для событий мыши */
  overflow-y: auto;
  margin: 0;
  padding: 0;
}

/* ширина модального окна и его отступы от экрана */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
    margin: 30px auto; /* для отображения модального окна по центру */
  }
}
/* свойства для блока, содержащего контент модального окна */
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0;
}
@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
    box-shadow: 0 5px 15px rgba(0,0,0,.5);
  }
}
/* свойства для заголовка модального окна */
.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #eceeef;
}
.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
  color:#000000;
}
/* свойства для кнопки "Закрыть" */
.close {
  float: right;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  text-decoration: none;
}
/* свойства для кнопки "Закрыть" при нахождении её в фокусе или наведении */
.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .75;
}
/* свойства для блока, содержащего основное содержимое окна */
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 15px;
  overflow: auto;
  color:#000000;
  form {
    > div {
      width: 100%;
      input {
        width: 100%;
        border: 1px solid #C0C0C0;
        border-radius: 10px;
        font-size: 16px;
        padding: 0 10px;
      }
      select {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMSAxTDYgNkwxIDEiIHN0cm9rZT0iI0MwQzBDMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 98% 60%;
        width: 100%;
        margin-left: 0 !important;
      }
    }
    > button {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      border-radius: 10px;
      padding: 12px 25px;
      max-width: 243px;
      width: 100%;
      border: none;
      background: #3C3C3C;
      color: #FFFFFF;
      text-transform: uppercase;
      margin-top: 10px;
      cursor: pointer;
      .sm(margin-bottom, 10px);
      &:not(:last-child){
        margin-right: 20px;
      }
      &.lime {
        background: #93C01F;
      }
    }
  }
}
.select-projects {
  &__control {
    min-height: 32px !important;
    border-radius: 10px !important;
  }
  &__multi-value {
    &__label {
      font-size: 75% !important;
      line-height: 16px !important;
    }
  }
  &__input-container {
    line-height: 16px !important;
    &__input {
      font-size: 13px !important;
    }
  }
  &__indicator {
    padding: 4px 8px !important;
    path {
      color: silver;
    }
  }
}
.select-years-filter {
  min-width: 188px;
  margin-left: 20px;
  .select-years__control {
    min-height: 32px !important;
    border-radius: 10px !important;
  }
  .select-years__single-value {
    line-height: 26px !important;
    font-size: 15px !important;
  }
  .select-years__value-container {
    input {
      line-height: 26px !important;
    }
  }
  .select-years__indicator {
    padding: 4px 8px !important;
    path {
      color: silver;
    }
  }
}
.select-months-filter {
  min-width: 188px;
  margin-left: 20px;
  .select-months__control {
    min-height: 32px !important;
    border-radius: 10px !important;
  }
  .select-months__multi-value__label {
    font-size: 75% !important;
    line-height: 16px !important;
  }
  .select-months__value-container {
    input {
      line-height: 26px !important;
    }
  }
  .select-months__indicator{
    padding: 4px 8px !important;
    path {
      color: silver;
    }
  }
}
