.custom-select-container {
    display: inline-block;
    min-width: 60px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

    .custom-select-container .selected-text {
        background-color: transparent;
        color: #FFFFFF;
        line-height: 24px;
        font-size: 16px;
        padding: 6px 30px 6px 6px;
        /*    border-bottom: 1px solid #2D2E2E;*/
    }

        .custom-select-container .selected-text::after {
            content: "";
            position: absolute;
            right: 10px;
            top: 16px;
            border: 7px solid transparent;
            border-color: #fff transparent transparent transparent;
        }

        .custom-select-container .selected-text.active::after {
            top: 8px;
            border-color: transparent transparent #fff transparent;
        }

    .custom-select-container ul {
        margin: 0;
        padding: 0;
        text-align: center;
        border-radius: 6px;
        border: 1px solid transparent;
        overflow: hidden;
        z-index: 100;
    }

.custom-select-container .select-options {
    position: absolute;
    width: 100%;
}

    .custom-select-container li {
        list-style-type: none;
        padding: 6px 6px;
        line-height: 24px;
        font-size: 16px;
        background: #2D2E2E;
        /*    border-bottom: 1px solid #37b0b0;*/
        color: #ffffff;
        cursor: pointer;
    }

    .custom-select-container li:hover {
        background-color: #4D4D4D;
        color: #ffffff;
    }
