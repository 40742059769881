.styled-button {
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    background-color: #D8AF62;
    border: 1px solid #D8AF62;
    color: white;
    /*font-family: 'Open Sans';*/
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 15px;
    min-width: 150px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.styled-button:hover {
    background-color: transparent;
    border: 1px solid #D8AF62;
    color: #D8AF62;
}
