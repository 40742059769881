
/* This fires as soon as the element enters the DOM*/
.x-main-menu-enter {
    /*We give the list the initial dimension of the list button*/
    max-height: 0;
    opacity: 0;
    padding: 0px 0px 0px 0px;
}
/* This is where we can add the transition*/
.x-main-menu-enter-active {
    max-height: 40px;
    opacity: 1;
    padding: 0px 5px 0px 10px;
    transition: all 300ms;
}
/* This fires as soon as the this.state.showList is false */
.x-main-menu-exit {
    max-height: 40px;
    opacity: 1;
    padding: 0px 5px 0px 10px;
}
/* fires as element leaves the DOM*/
.x-main-menu-exit-active {
    max-height: 0;
    opacity: 0;
    padding: 0px 0px 0px 0px;
    transition: all 300ms;
}

.my-node-enter {
    opacity: 0;
    max-height: 0;
}

.my-node-enter-active {
    opacity: 1;
    height: 50px;
    transition: all 300ms;
}

.my-node-exit {
    opacity: 1;
    height: 50px;
}

.my-node-exit-active {
    opacity: 0;
    max-height: 0;
    transition: all 300ms;
}